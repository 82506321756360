<template>
  <div
    style="
      overflow: hidden;
      position: relative;
      width: 100%;
      min-height: calc(100vh - 40px);
    "
    class="justify-center align-center white--text d-flex"
  >
    <div class="d-flex flex-column justify-center align-center pa-3 white--text">
      <v-img
        width="300"
        contain
        :src="require('@/assets/bugCatcher/finishBanner.png')"
        class="d-flex align-end justify-center text-center"
        ><div class="d-flex mb-7 justify-center text-center text-h4">
          {{ $t("string.finished") }}
        </div></v-img
      >
      <v-progress-linear
        color="brown darken-1"
        indeterminate
        height="5"
        style="max-width: 200px"
        class="mt-5"
      ></v-progress-linear>
      <div class="brown--text">
        Saving...
      </div>
      
    </div>
    <div class="wrapper">
      <div class="confetti-149"></div>
      <div class="confetti-148"></div>
      <div class="confetti-147"></div>
      <div class="confetti-146"></div>
      <div class="confetti-145"></div>
      <div class="confetti-144"></div>
      <div class="confetti-143"></div>
      <div class="confetti-142"></div>
      <div class="confetti-141"></div>
      <div class="confetti-140"></div>
      <div class="confetti-139"></div>
      <div class="confetti-138"></div>
      <div class="confetti-137"></div>
      <div class="confetti-136"></div>
      <div class="confetti-135"></div>
      <div class="confetti-134"></div>
      <div class="confetti-133"></div>
      <div class="confetti-132"></div>
      <div class="confetti-131"></div>
      <div class="confetti-130"></div>
      <div class="confetti-129"></div>
      <div class="confetti-128"></div>
      <div class="confetti-127"></div>
      <div class="confetti-126"></div>
      <div class="confetti-125"></div>
      <div class="confetti-124"></div>
      <div class="confetti-123"></div>
      <div class="confetti-122"></div>
      <div class="confetti-121"></div>
      <div class="confetti-120"></div>
      <div class="confetti-119"></div>
      <div class="confetti-118"></div>
      <div class="confetti-117"></div>
      <div class="confetti-116"></div>
      <div class="confetti-115"></div>
      <div class="confetti-114"></div>
      <div class="confetti-113"></div>
      <div class="confetti-112"></div>
      <div class="confetti-111"></div>
      <div class="confetti-110"></div>
      <div class="confetti-109"></div>
      <div class="confetti-108"></div>
      <div class="confetti-107"></div>
      <div class="confetti-106"></div>
      <div class="confetti-105"></div>
      <div class="confetti-104"></div>
      <div class="confetti-103"></div>
      <div class="confetti-102"></div>
      <div class="confetti-101"></div>
      <div class="confetti-100"></div>
      <div class="confetti-99"></div>
      <div class="confetti-98"></div>
      <div class="confetti-97"></div>
      <div class="confetti-96"></div>
      <div class="confetti-95"></div>
      <div class="confetti-94"></div>
      <div class="confetti-93"></div>
      <div class="confetti-92"></div>
      <div class="confetti-91"></div>
      <div class="confetti-90"></div>
      <div class="confetti-89"></div>
      <div class="confetti-88"></div>
      <div class="confetti-87"></div>
      <div class="confetti-86"></div>
      <div class="confetti-85"></div>
      <div class="confetti-84"></div>
      <div class="confetti-83"></div>
      <div class="confetti-82"></div>
      <div class="confetti-81"></div>
      <div class="confetti-80"></div>
      <div class="confetti-79"></div>
      <div class="confetti-78"></div>
      <div class="confetti-77"></div>
      <div class="confetti-76"></div>
      <div class="confetti-75"></div>
      <div class="confetti-74"></div>
      <div class="confetti-73"></div>
      <div class="confetti-72"></div>
      <div class="confetti-71"></div>
      <div class="confetti-70"></div>
      <div class="confetti-69"></div>
      <div class="confetti-68"></div>
      <div class="confetti-67"></div>
      <div class="confetti-66"></div>
      <div class="confetti-65"></div>
      <div class="confetti-64"></div>
      <div class="confetti-63"></div>
      <div class="confetti-62"></div>
      <div class="confetti-61"></div>
      <div class="confetti-60"></div>
      <div class="confetti-59"></div>
      <div class="confetti-58"></div>
      <div class="confetti-57"></div>
      <div class="confetti-56"></div>
      <div class="confetti-55"></div>
      <div class="confetti-54"></div>
      <div class="confetti-53"></div>
      <div class="confetti-52"></div>
      <div class="confetti-51"></div>
      <div class="confetti-50"></div>
      <div class="confetti-49"></div>
      <div class="confetti-48"></div>
      <div class="confetti-47"></div>
      <div class="confetti-46"></div>
      <div class="confetti-45"></div>
      <div class="confetti-44"></div>
      <div class="confetti-43"></div>
      <div class="confetti-42"></div>
      <div class="confetti-41"></div>
      <div class="confetti-40"></div>
      <div class="confetti-39"></div>
      <div class="confetti-38"></div>
      <div class="confetti-37"></div>
      <div class="confetti-36"></div>
      <div class="confetti-35"></div>
      <div class="confetti-34"></div>
      <div class="confetti-33"></div>
      <div class="confetti-32"></div>
      <div class="confetti-31"></div>
      <div class="confetti-30"></div>
      <div class="confetti-29"></div>
      <div class="confetti-28"></div>
      <div class="confetti-27"></div>
      <div class="confetti-26"></div>
      <div class="confetti-25"></div>
      <div class="confetti-24"></div>
      <div class="confetti-23"></div>
      <div class="confetti-22"></div>
      <div class="confetti-21"></div>
      <div class="confetti-20"></div>
      <div class="confetti-19"></div>
      <div class="confetti-18"></div>
      <div class="confetti-17"></div>
      <div class="confetti-16"></div>
      <div class="confetti-15"></div>
      <div class="confetti-14"></div>
      <div class="confetti-13"></div>
      <div class="confetti-12"></div>
      <div class="confetti-11"></div>
      <div class="confetti-10"></div>
      <div class="confetti-9"></div>
      <div class="confetti-8"></div>
      <div class="confetti-7"></div>
      <div class="confetti-6"></div>
      <div class="confetti-5"></div>
      <div class="confetti-4"></div>
      <div class="confetti-3"></div>
      <div class="confetti-2"></div>
      <div class="confetti-1"></div>
      <div class="confetti-0"></div>
    </div>
  </div>
</template>
    
<script>
import { mapState } from "vuex";
export default {
  props: ["callback"],
  data: () => ({
    dialog: true,
  }),
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  mounted() {
    let Sfx = new Audio(require("@/assets/sounds/finish.wav"));
    Sfx.play();
    Sfx.volume = this.settings.audio.sfx * this.settings.audio.master;
  },
  methods: {
    //
  },
};
</script>
    
<style scoped>
.wrapper {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

[class|="confetti"] {
  position: absolute;
}

.confetti-0 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 6%;
  opacity: 1.083068137;
  transform: rotate(246.1657451294deg);
  -webkit-animation: drop-0 4.5448391071s 0.6140502362s infinite;
  animation: drop-0 4.5448391071s 0.6140502362s infinite;
}

@-webkit-keyframes drop-0 {
  100% {
    top: 110%;
    left: 15%;
  }
}

@keyframes drop-0 {
  100% {
    top: 110%;
    left: 15%;
  }
}
.confetti-1 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 82%;
  opacity: 0.8376949715;
  transform: rotate(213.6683352386deg);
  -webkit-animation: drop-1 4.0426784509s 0.3007588172s infinite;
  animation: drop-1 4.0426784509s 0.3007588172s infinite;
}

@-webkit-keyframes drop-1 {
  100% {
    top: 110%;
    left: 84%;
  }
}

@keyframes drop-1 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-2 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 15%;
  opacity: 0.8286116675;
  transform: rotate(34.2851135731deg);
  -webkit-animation: drop-2 4.324459329s 0.9464354185s infinite;
  animation: drop-2 4.324459329s 0.9464354185s infinite;
}

@-webkit-keyframes drop-2 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-2 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-3 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 50%;
  opacity: 0.7142608611;
  transform: rotate(305.2888388103deg);
  -webkit-animation: drop-3 4.2347949538s 0.5850282241s infinite;
  animation: drop-3 4.2347949538s 0.5850282241s infinite;
}

@-webkit-keyframes drop-3 {
  100% {
    top: 110%;
    left: 55%;
  }
}

@keyframes drop-3 {
  100% {
    top: 110%;
    left: 55%;
  }
}
.confetti-4 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 5%;
  opacity: 0.9101587673;
  transform: rotate(237.7008202659deg);
  -webkit-animation: drop-4 4.3132521586s 0.0059435334s infinite;
  animation: drop-4 4.3132521586s 0.0059435334s infinite;
}

@-webkit-keyframes drop-4 {
  100% {
    top: 110%;
    left: 17%;
  }
}

@keyframes drop-4 {
  100% {
    top: 110%;
    left: 17%;
  }
}
.confetti-5 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 31%;
  opacity: 0.5203485589;
  transform: rotate(105.0273873634deg);
  -webkit-animation: drop-5 4.0699925862s 0.7615887642s infinite;
  animation: drop-5 4.0699925862s 0.7615887642s infinite;
}

@-webkit-keyframes drop-5 {
  100% {
    top: 110%;
    left: 46%;
  }
}

@keyframes drop-5 {
  100% {
    top: 110%;
    left: 46%;
  }
}
.confetti-6 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 84%;
  opacity: 0.6836469513;
  transform: rotate(172.4786366513deg);
  -webkit-animation: drop-6 4.0856801571s 0.1020076821s infinite;
  animation: drop-6 4.0856801571s 0.1020076821s infinite;
}

@-webkit-keyframes drop-6 {
  100% {
    top: 110%;
    left: 88%;
  }
}

@keyframes drop-6 {
  100% {
    top: 110%;
    left: 88%;
  }
}
.confetti-7 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 70%;
  opacity: 1.0577153956;
  transform: rotate(333.6836412904deg);
  -webkit-animation: drop-7 4.5239105102s 0.7263020066s infinite;
  animation: drop-7 4.5239105102s 0.7263020066s infinite;
}

@-webkit-keyframes drop-7 {
  100% {
    top: 110%;
    left: 71%;
  }
}

@keyframes drop-7 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.confetti-8 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 83%;
  opacity: 0.8890204775;
  transform: rotate(344.7299978362deg);
  -webkit-animation: drop-8 4.9391845944s 0.2874486804s infinite;
  animation: drop-8 4.9391845944s 0.2874486804s infinite;
}

@-webkit-keyframes drop-8 {
  100% {
    top: 110%;
    left: 93%;
  }
}

@keyframes drop-8 {
  100% {
    top: 110%;
    left: 93%;
  }
}
.confetti-9 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 10%;
  opacity: 0.7855342687;
  transform: rotate(265.6929268412deg);
  -webkit-animation: drop-9 4.1233810086s 0.5488648638s infinite;
  animation: drop-9 4.1233810086s 0.5488648638s infinite;
}

@-webkit-keyframes drop-9 {
  100% {
    top: 110%;
    left: 24%;
  }
}

@keyframes drop-9 {
  100% {
    top: 110%;
    left: 24%;
  }
}
.confetti-10 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 21%;
  opacity: 0.6220425378;
  transform: rotate(109.263259792deg);
  -webkit-animation: drop-10 4.3952324649s 0.6977450373s infinite;
  animation: drop-10 4.3952324649s 0.6977450373s infinite;
}

@-webkit-keyframes drop-10 {
  100% {
    top: 110%;
    left: 36%;
  }
}

@keyframes drop-10 {
  100% {
    top: 110%;
    left: 36%;
  }
}
.confetti-11 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 31%;
  opacity: 0.9749140618;
  transform: rotate(71.9461407938deg);
  -webkit-animation: drop-11 4.1843117949s 0.0810570758s infinite;
  animation: drop-11 4.1843117949s 0.0810570758s infinite;
}

@-webkit-keyframes drop-11 {
  100% {
    top: 110%;
    left: 38%;
  }
}

@keyframes drop-11 {
  100% {
    top: 110%;
    left: 38%;
  }
}
.confetti-12 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 29%;
  opacity: 1.0451106041;
  transform: rotate(86.7964605898deg);
  -webkit-animation: drop-12 4.6362096169s 0.6721333505s infinite;
  animation: drop-12 4.6362096169s 0.6721333505s infinite;
}

@-webkit-keyframes drop-12 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-12 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.confetti-13 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 78%;
  opacity: 1.3284106283;
  transform: rotate(145.9075718389deg);
  -webkit-animation: drop-13 4.6809783119s 0.5411244943s infinite;
  animation: drop-13 4.6809783119s 0.5411244943s infinite;
}

@-webkit-keyframes drop-13 {
  100% {
    top: 110%;
    left: 85%;
  }
}

@keyframes drop-13 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.confetti-14 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 66%;
  opacity: 1.1293356224;
  transform: rotate(138.2321594457deg);
  -webkit-animation: drop-14 4.9846244153s 0.8931102133s infinite;
  animation: drop-14 4.9846244153s 0.8931102133s infinite;
}

@-webkit-keyframes drop-14 {
  100% {
    top: 110%;
    left: 68%;
  }
}

@keyframes drop-14 {
  100% {
    top: 110%;
    left: 68%;
  }
}
.confetti-15 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 97%;
  opacity: 0.6240950216;
  transform: rotate(262.9124112769deg);
  -webkit-animation: drop-15 4.3891284199s 0.1734577366s infinite;
  animation: drop-15 4.3891284199s 0.1734577366s infinite;
}

@-webkit-keyframes drop-15 {
  100% {
    top: 110%;
    left: 108%;
  }
}

@keyframes drop-15 {
  100% {
    top: 110%;
    left: 108%;
  }
}
.confetti-16 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 23%;
  opacity: 1.0026599353;
  transform: rotate(273.2951253658deg);
  -webkit-animation: drop-16 4.4322393779s 0.3084468507s infinite;
  animation: drop-16 4.4322393779s 0.3084468507s infinite;
}

@-webkit-keyframes drop-16 {
  100% {
    top: 110%;
    left: 30%;
  }
}

@keyframes drop-16 {
  100% {
    top: 110%;
    left: 30%;
  }
}
.confetti-17 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 56%;
  opacity: 1.3209049461;
  transform: rotate(306.9330806101deg);
  -webkit-animation: drop-17 4.876491297s 0.9939347228s infinite;
  animation: drop-17 4.876491297s 0.9939347228s infinite;
}

@-webkit-keyframes drop-17 {
  100% {
    top: 110%;
    left: 71%;
  }
}

@keyframes drop-17 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.confetti-18 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 23%;
  opacity: 1.3325574015;
  transform: rotate(255.4950605989deg);
  -webkit-animation: drop-18 4.1801206239s 0.479105339s infinite;
  animation: drop-18 4.1801206239s 0.479105339s infinite;
}

@-webkit-keyframes drop-18 {
  100% {
    top: 110%;
    left: 32%;
  }
}

@keyframes drop-18 {
  100% {
    top: 110%;
    left: 32%;
  }
}
.confetti-19 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 38%;
  opacity: 0.9931726759;
  transform: rotate(151.164512753deg);
  -webkit-animation: drop-19 4.3003676356s 0.8087503801s infinite;
  animation: drop-19 4.3003676356s 0.8087503801s infinite;
}

@-webkit-keyframes drop-19 {
  100% {
    top: 110%;
    left: 41%;
  }
}

@keyframes drop-19 {
  100% {
    top: 110%;
    left: 41%;
  }
}
.confetti-20 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 61%;
  opacity: 0.5665658861;
  transform: rotate(195.6768277843deg);
  -webkit-animation: drop-20 4.1187966486s 0.8306562015s infinite;
  animation: drop-20 4.1187966486s 0.8306562015s infinite;
}

@-webkit-keyframes drop-20 {
  100% {
    top: 110%;
    left: 63%;
  }
}

@keyframes drop-20 {
  100% {
    top: 110%;
    left: 63%;
  }
}
.confetti-21 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 80%;
  opacity: 0.9061561938;
  transform: rotate(128.0713771593deg);
  -webkit-animation: drop-21 4.5425858153s 0.4538894472s infinite;
  animation: drop-21 4.5425858153s 0.4538894472s infinite;
}

@-webkit-keyframes drop-21 {
  100% {
    top: 110%;
    left: 84%;
  }
}

@keyframes drop-21 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-22 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 45%;
  opacity: 0.9024511016;
  transform: rotate(252.6625402035deg);
  -webkit-animation: drop-22 4.4389359527s 0.5345425956s infinite;
  animation: drop-22 4.4389359527s 0.5345425956s infinite;
}

@-webkit-keyframes drop-22 {
  100% {
    top: 110%;
    left: 53%;
  }
}

@keyframes drop-22 {
  100% {
    top: 110%;
    left: 53%;
  }
}
.confetti-23 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 38%;
  opacity: 0.7442551357;
  transform: rotate(9.4586729792deg);
  -webkit-animation: drop-23 4.9615027672s 0.5982037083s infinite;
  animation: drop-23 4.9615027672s 0.5982037083s infinite;
}

@-webkit-keyframes drop-23 {
  100% {
    top: 110%;
    left: 47%;
  }
}

@keyframes drop-23 {
  100% {
    top: 110%;
    left: 47%;
  }
}
.confetti-24 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 40%;
  opacity: 1.0409877839;
  transform: rotate(251.5395044949deg);
  -webkit-animation: drop-24 4.25651997s 0.1861044005s infinite;
  animation: drop-24 4.25651997s 0.1861044005s infinite;
}

@-webkit-keyframes drop-24 {
  100% {
    top: 110%;
    left: 47%;
  }
}

@keyframes drop-24 {
  100% {
    top: 110%;
    left: 47%;
  }
}
.confetti-25 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 97%;
  opacity: 0.9962287051;
  transform: rotate(130.878184025deg);
  -webkit-animation: drop-25 4.0701601747s 0.2152526956s infinite;
  animation: drop-25 4.0701601747s 0.2152526956s infinite;
}

@-webkit-keyframes drop-25 {
  100% {
    top: 110%;
    left: 108%;
  }
}

@keyframes drop-25 {
  100% {
    top: 110%;
    left: 108%;
  }
}
.confetti-26 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 82%;
  opacity: 1.0389846736;
  transform: rotate(230.2908227837deg);
  -webkit-animation: drop-26 4.5201499085s 0.2742354129s infinite;
  animation: drop-26 4.5201499085s 0.2742354129s infinite;
}

@-webkit-keyframes drop-26 {
  100% {
    top: 110%;
    left: 84%;
  }
}

@keyframes drop-26 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-27 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 93%;
  opacity: 1.4933592896;
  transform: rotate(234.9094199393deg);
  -webkit-animation: drop-27 4.8509693745s 0.0515324417s infinite;
  animation: drop-27 4.8509693745s 0.0515324417s infinite;
}

@-webkit-keyframes drop-27 {
  100% {
    top: 110%;
    left: 104%;
  }
}

@keyframes drop-27 {
  100% {
    top: 110%;
    left: 104%;
  }
}
.confetti-28 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 78%;
  opacity: 0.8165689999;
  transform: rotate(270.8309195041deg);
  -webkit-animation: drop-28 4.0760083808s 0.9815852831s infinite;
  animation: drop-28 4.0760083808s 0.9815852831s infinite;
}

@-webkit-keyframes drop-28 {
  100% {
    top: 110%;
    left: 87%;
  }
}

@keyframes drop-28 {
  100% {
    top: 110%;
    left: 87%;
  }
}
.confetti-29 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 99%;
  opacity: 1.3990559648;
  transform: rotate(268.7152093577deg);
  -webkit-animation: drop-29 4.8787432582s 0.1893232388s infinite;
  animation: drop-29 4.8787432582s 0.1893232388s infinite;
}

@-webkit-keyframes drop-29 {
  100% {
    top: 110%;
    left: 100%;
  }
}

@keyframes drop-29 {
  100% {
    top: 110%;
    left: 100%;
  }
}
.confetti-30 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 82%;
  opacity: 1.1869597329;
  transform: rotate(104.7439204291deg);
  -webkit-animation: drop-30 4.9888882751s 0.2468889395s infinite;
  animation: drop-30 4.9888882751s 0.2468889395s infinite;
}

@-webkit-keyframes drop-30 {
  100% {
    top: 110%;
    left: 85%;
  }
}

@keyframes drop-30 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.confetti-31 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 90%;
  opacity: 1.1337721251;
  transform: rotate(112.9762682411deg);
  -webkit-animation: drop-31 4.2392207157s 0.1515776271s infinite;
  animation: drop-31 4.2392207157s 0.1515776271s infinite;
}

@-webkit-keyframes drop-31 {
  100% {
    top: 110%;
    left: 101%;
  }
}

@keyframes drop-31 {
  100% {
    top: 110%;
    left: 101%;
  }
}
.confetti-32 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 20%;
  opacity: 0.8384450136;
  transform: rotate(21.7568687482deg);
  -webkit-animation: drop-32 4.5798674588s 0.4178022081s infinite;
  animation: drop-32 4.5798674588s 0.4178022081s infinite;
}

@-webkit-keyframes drop-32 {
  100% {
    top: 110%;
    left: 33%;
  }
}

@keyframes drop-32 {
  100% {
    top: 110%;
    left: 33%;
  }
}
.confetti-33 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 20%;
  opacity: 1.4170959366;
  transform: rotate(247.8365609165deg);
  -webkit-animation: drop-33 4.7802539604s 0.2033102827s infinite;
  animation: drop-33 4.7802539604s 0.2033102827s infinite;
}

@-webkit-keyframes drop-33 {
  100% {
    top: 110%;
    left: 35%;
  }
}

@keyframes drop-33 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-34 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 95%;
  opacity: 0.7730585768;
  transform: rotate(83.3118419242deg);
  -webkit-animation: drop-34 4.3520735674s 0.3469602139s infinite;
  animation: drop-34 4.3520735674s 0.3469602139s infinite;
}

@-webkit-keyframes drop-34 {
  100% {
    top: 110%;
    left: 106%;
  }
}

@keyframes drop-34 {
  100% {
    top: 110%;
    left: 106%;
  }
}
.confetti-35 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 12%;
  opacity: 0.8663044987;
  transform: rotate(351.9759713291deg);
  -webkit-animation: drop-35 4.3676997394s 0.1515395058s infinite;
  animation: drop-35 4.3676997394s 0.1515395058s infinite;
}

@-webkit-keyframes drop-35 {
  100% {
    top: 110%;
    left: 18%;
  }
}

@keyframes drop-35 {
  100% {
    top: 110%;
    left: 18%;
  }
}
.confetti-36 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 58%;
  opacity: 1.1074183931;
  transform: rotate(195.6577323903deg);
  -webkit-animation: drop-36 4.3709198603s 0.0343960361s infinite;
  animation: drop-36 4.3709198603s 0.0343960361s infinite;
}

@-webkit-keyframes drop-36 {
  100% {
    top: 110%;
    left: 73%;
  }
}

@keyframes drop-36 {
  100% {
    top: 110%;
    left: 73%;
  }
}
.confetti-37 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 19%;
  opacity: 1.381912009;
  transform: rotate(303.1674193191deg);
  -webkit-animation: drop-37 4.0009051815s 0.7300479921s infinite;
  animation: drop-37 4.0009051815s 0.7300479921s infinite;
}

@-webkit-keyframes drop-37 {
  100% {
    top: 110%;
    left: 24%;
  }
}

@keyframes drop-37 {
  100% {
    top: 110%;
    left: 24%;
  }
}
.confetti-38 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 86%;
  opacity: 0.8236690741;
  transform: rotate(284.9426364791deg);
  -webkit-animation: drop-38 4.3484335846s 0.3933964927s infinite;
  animation: drop-38 4.3484335846s 0.3933964927s infinite;
}

@-webkit-keyframes drop-38 {
  100% {
    top: 110%;
    left: 92%;
  }
}

@keyframes drop-38 {
  100% {
    top: 110%;
    left: 92%;
  }
}
.confetti-39 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 1%;
  opacity: 0.9373645582;
  transform: rotate(313.0569042831deg);
  -webkit-animation: drop-39 4.9912451863s 0.906201931s infinite;
  animation: drop-39 4.9912451863s 0.906201931s infinite;
}

@-webkit-keyframes drop-39 {
  100% {
    top: 110%;
    left: 14%;
  }
}

@keyframes drop-39 {
  100% {
    top: 110%;
    left: 14%;
  }
}
.confetti-40 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 39%;
  opacity: 0.5984415715;
  transform: rotate(42.6945979854deg);
  -webkit-animation: drop-40 4.1974431527s 0.4613649076s infinite;
  animation: drop-40 4.1974431527s 0.4613649076s infinite;
}

@-webkit-keyframes drop-40 {
  100% {
    top: 110%;
    left: 45%;
  }
}

@keyframes drop-40 {
  100% {
    top: 110%;
    left: 45%;
  }
}
.confetti-41 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 14%;
  opacity: 1.121709148;
  transform: rotate(111.9711534769deg);
  -webkit-animation: drop-41 4.9120483668s 0.783945731s infinite;
  animation: drop-41 4.9120483668s 0.783945731s infinite;
}

@-webkit-keyframes drop-41 {
  100% {
    top: 110%;
    left: 20%;
  }
}

@keyframes drop-41 {
  100% {
    top: 110%;
    left: 20%;
  }
}
.confetti-42 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 52%;
  opacity: 1.4549997335;
  transform: rotate(346.5259847156deg);
  -webkit-animation: drop-42 4.2623257941s 0.1850912097s infinite;
  animation: drop-42 4.2623257941s 0.1850912097s infinite;
}

@-webkit-keyframes drop-42 {
  100% {
    top: 110%;
    left: 57%;
  }
}

@keyframes drop-42 {
  100% {
    top: 110%;
    left: 57%;
  }
}
.confetti-43 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 62%;
  opacity: 1.4640404741;
  transform: rotate(264.1639270509deg);
  -webkit-animation: drop-43 4.1260586294s 0.6116832528s infinite;
  animation: drop-43 4.1260586294s 0.6116832528s infinite;
}

@-webkit-keyframes drop-43 {
  100% {
    top: 110%;
    left: 75%;
  }
}

@keyframes drop-43 {
  100% {
    top: 110%;
    left: 75%;
  }
}
.confetti-44 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 56%;
  opacity: 0.6605575792;
  transform: rotate(11.7266223204deg);
  -webkit-animation: drop-44 4.2924039865s 0.5583666323s infinite;
  animation: drop-44 4.2924039865s 0.5583666323s infinite;
}

@-webkit-keyframes drop-44 {
  100% {
    top: 110%;
    left: 60%;
  }
}

@keyframes drop-44 {
  100% {
    top: 110%;
    left: 60%;
  }
}
.confetti-45 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 65%;
  opacity: 0.8004420459;
  transform: rotate(341.1838707002deg);
  -webkit-animation: drop-45 4.2115083376s 0.7118071851s infinite;
  animation: drop-45 4.2115083376s 0.7118071851s infinite;
}

@-webkit-keyframes drop-45 {
  100% {
    top: 110%;
    left: 66%;
  }
}

@keyframes drop-45 {
  100% {
    top: 110%;
    left: 66%;
  }
}
.confetti-46 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 17%;
  opacity: 0.9492002371;
  transform: rotate(276.3891282583deg);
  -webkit-animation: drop-46 4.4494126763s 0.1519249163s infinite;
  animation: drop-46 4.4494126763s 0.1519249163s infinite;
}

@-webkit-keyframes drop-46 {
  100% {
    top: 110%;
    left: 23%;
  }
}

@keyframes drop-46 {
  100% {
    top: 110%;
    left: 23%;
  }
}
.confetti-47 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 53%;
  opacity: 0.6270943657;
  transform: rotate(102.1669656861deg);
  -webkit-animation: drop-47 4.3987492389s 0.5938664333s infinite;
  animation: drop-47 4.3987492389s 0.5938664333s infinite;
}

@-webkit-keyframes drop-47 {
  100% {
    top: 110%;
    left: 66%;
  }
}

@keyframes drop-47 {
  100% {
    top: 110%;
    left: 66%;
  }
}
.confetti-48 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 5%;
  opacity: 0.6009974515;
  transform: rotate(243.5545315985deg);
  -webkit-animation: drop-48 4.4510635348s 0.2312615553s infinite;
  animation: drop-48 4.4510635348s 0.2312615553s infinite;
}

@-webkit-keyframes drop-48 {
  100% {
    top: 110%;
    left: 18%;
  }
}

@keyframes drop-48 {
  100% {
    top: 110%;
    left: 18%;
  }
}
.confetti-49 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 5%;
  opacity: 0.6900240703;
  transform: rotate(117.5438656123deg);
  -webkit-animation: drop-49 4.9786132098s 0.2887447928s infinite;
  animation: drop-49 4.9786132098s 0.2887447928s infinite;
}

@-webkit-keyframes drop-49 {
  100% {
    top: 110%;
    left: 7%;
  }
}

@keyframes drop-49 {
  100% {
    top: 110%;
    left: 7%;
  }
}
.confetti-50 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 88%;
  opacity: 1.2135005857;
  transform: rotate(167.9881929146deg);
  -webkit-animation: drop-50 4.1625648627s 0.9958664924s infinite;
  animation: drop-50 4.1625648627s 0.9958664924s infinite;
}

@-webkit-keyframes drop-50 {
  100% {
    top: 110%;
    left: 90%;
  }
}

@keyframes drop-50 {
  100% {
    top: 110%;
    left: 90%;
  }
}
.confetti-51 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 22%;
  opacity: 0.5896871153;
  transform: rotate(289.77363825deg);
  -webkit-animation: drop-51 4.6818073347s 0.1265720431s infinite;
  animation: drop-51 4.6818073347s 0.1265720431s infinite;
}

@-webkit-keyframes drop-51 {
  100% {
    top: 110%;
    left: 27%;
  }
}

@keyframes drop-51 {
  100% {
    top: 110%;
    left: 27%;
  }
}
.confetti-52 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 97%;
  opacity: 1.1879585305;
  transform: rotate(99.0352342095deg);
  -webkit-animation: drop-52 4.0991624515s 0.6633652406s infinite;
  animation: drop-52 4.0991624515s 0.6633652406s infinite;
}

@-webkit-keyframes drop-52 {
  100% {
    top: 110%;
    left: 108%;
  }
}

@keyframes drop-52 {
  100% {
    top: 110%;
    left: 108%;
  }
}
.confetti-53 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 76%;
  opacity: 0.8954021533;
  transform: rotate(110.9864259195deg);
  -webkit-animation: drop-53 4.6925167565s 0.7532335815s infinite;
  animation: drop-53 4.6925167565s 0.7532335815s infinite;
}

@-webkit-keyframes drop-53 {
  100% {
    top: 110%;
    left: 82%;
  }
}

@keyframes drop-53 {
  100% {
    top: 110%;
    left: 82%;
  }
}
.confetti-54 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 30%;
  opacity: 0.946939877;
  transform: rotate(102.8420660901deg);
  -webkit-animation: drop-54 4.4912431569s 0.6352908119s infinite;
  animation: drop-54 4.4912431569s 0.6352908119s infinite;
}

@-webkit-keyframes drop-54 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-54 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.confetti-55 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 38%;
  opacity: 0.5602234166;
  transform: rotate(337.8142389417deg);
  -webkit-animation: drop-55 4.8290559256s 0.0362671825s infinite;
  animation: drop-55 4.8290559256s 0.0362671825s infinite;
}

@-webkit-keyframes drop-55 {
  100% {
    top: 110%;
    left: 48%;
  }
}

@keyframes drop-55 {
  100% {
    top: 110%;
    left: 48%;
  }
}
.confetti-56 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 17%;
  opacity: 0.5535289919;
  transform: rotate(94.5841246282deg);
  -webkit-animation: drop-56 4.9751267095s 0.1212333021s infinite;
  animation: drop-56 4.9751267095s 0.1212333021s infinite;
}

@-webkit-keyframes drop-56 {
  100% {
    top: 110%;
    left: 30%;
  }
}

@keyframes drop-56 {
  100% {
    top: 110%;
    left: 30%;
  }
}
.confetti-57 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 23%;
  opacity: 0.7554779162;
  transform: rotate(75.9010882887deg);
  -webkit-animation: drop-57 4.5018483349s 0.7017247705s infinite;
  animation: drop-57 4.5018483349s 0.7017247705s infinite;
}

@-webkit-keyframes drop-57 {
  100% {
    top: 110%;
    left: 29%;
  }
}

@keyframes drop-57 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.confetti-58 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 97%;
  opacity: 0.7389629932;
  transform: rotate(71.2511836298deg);
  -webkit-animation: drop-58 4.5895899057s 0.9921291292s infinite;
  animation: drop-58 4.5895899057s 0.9921291292s infinite;
}

@-webkit-keyframes drop-58 {
  100% {
    top: 110%;
    left: 98%;
  }
}

@keyframes drop-58 {
  100% {
    top: 110%;
    left: 98%;
  }
}
.confetti-59 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 9%;
  opacity: 1.2428342466;
  transform: rotate(83.421502907deg);
  -webkit-animation: drop-59 4.5867889017s 0.7016619587s infinite;
  animation: drop-59 4.5867889017s 0.7016619587s infinite;
}

@-webkit-keyframes drop-59 {
  100% {
    top: 110%;
    left: 17%;
  }
}

@keyframes drop-59 {
  100% {
    top: 110%;
    left: 17%;
  }
}
.confetti-60 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 4%;
  opacity: 0.7711141042;
  transform: rotate(244.3825901654deg);
  -webkit-animation: drop-60 4.327367393s 0.3525669148s infinite;
  animation: drop-60 4.327367393s 0.3525669148s infinite;
}

@-webkit-keyframes drop-60 {
  100% {
    top: 110%;
    left: 9%;
  }
}

@keyframes drop-60 {
  100% {
    top: 110%;
    left: 9%;
  }
}
.confetti-61 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 49%;
  opacity: 0.7856435519;
  transform: rotate(131.9467984359deg);
  -webkit-animation: drop-61 4.973242036s 0.0198969403s infinite;
  animation: drop-61 4.973242036s 0.0198969403s infinite;
}

@-webkit-keyframes drop-61 {
  100% {
    top: 110%;
    left: 58%;
  }
}

@keyframes drop-61 {
  100% {
    top: 110%;
    left: 58%;
  }
}
.confetti-62 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 11%;
  opacity: 1.4027580845;
  transform: rotate(67.0977050243deg);
  -webkit-animation: drop-62 4.1120364461s 0.8103778346s infinite;
  animation: drop-62 4.1120364461s 0.8103778346s infinite;
}

@-webkit-keyframes drop-62 {
  100% {
    top: 110%;
    left: 23%;
  }
}

@keyframes drop-62 {
  100% {
    top: 110%;
    left: 23%;
  }
}
.confetti-63 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 10%;
  opacity: 0.702799936;
  transform: rotate(146.7692577574deg);
  -webkit-animation: drop-63 4.343722826s 0.0155664963s infinite;
  animation: drop-63 4.343722826s 0.0155664963s infinite;
}

@-webkit-keyframes drop-63 {
  100% {
    top: 110%;
    left: 24%;
  }
}

@keyframes drop-63 {
  100% {
    top: 110%;
    left: 24%;
  }
}
.confetti-64 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 55%;
  opacity: 0.966296162;
  transform: rotate(323.4810145987deg);
  -webkit-animation: drop-64 4.0015220126s 0.3904362347s infinite;
  animation: drop-64 4.0015220126s 0.3904362347s infinite;
}

@-webkit-keyframes drop-64 {
  100% {
    top: 110%;
    left: 61%;
  }
}

@keyframes drop-64 {
  100% {
    top: 110%;
    left: 61%;
  }
}
.confetti-65 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 77%;
  opacity: 1.1600700713;
  transform: rotate(1.8742539068deg);
  -webkit-animation: drop-65 4.2037125367s 0.5844288622s infinite;
  animation: drop-65 4.2037125367s 0.5844288622s infinite;
}

@-webkit-keyframes drop-65 {
  100% {
    top: 110%;
    left: 81%;
  }
}

@keyframes drop-65 {
  100% {
    top: 110%;
    left: 81%;
  }
}
.confetti-66 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 60%;
  opacity: 0.7138158716;
  transform: rotate(108.1903545395deg);
  -webkit-animation: drop-66 4.7182504896s 0.7438419188s infinite;
  animation: drop-66 4.7182504896s 0.7438419188s infinite;
}

@-webkit-keyframes drop-66 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-66 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.confetti-67 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 99%;
  opacity: 1.2012839068;
  transform: rotate(139.8182249215deg);
  -webkit-animation: drop-67 4.346289579s 0.4971559367s infinite;
  animation: drop-67 4.346289579s 0.4971559367s infinite;
}

@-webkit-keyframes drop-67 {
  100% {
    top: 110%;
    left: 111%;
  }
}

@keyframes drop-67 {
  100% {
    top: 110%;
    left: 111%;
  }
}
.confetti-68 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 86%;
  opacity: 0.6580494595;
  transform: rotate(115.7842924416deg);
  -webkit-animation: drop-68 4.7290287914s 0.3576736093s infinite;
  animation: drop-68 4.7290287914s 0.3576736093s infinite;
}

@-webkit-keyframes drop-68 {
  100% {
    top: 110%;
    left: 101%;
  }
}

@keyframes drop-68 {
  100% {
    top: 110%;
    left: 101%;
  }
}
.confetti-69 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 89%;
  opacity: 1.4036225729;
  transform: rotate(193.5743688216deg);
  -webkit-animation: drop-69 4.0276138084s 0.71217325s infinite;
  animation: drop-69 4.0276138084s 0.71217325s infinite;
}

@-webkit-keyframes drop-69 {
  100% {
    top: 110%;
    left: 92%;
  }
}

@keyframes drop-69 {
  100% {
    top: 110%;
    left: 92%;
  }
}
.confetti-70 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 85%;
  opacity: 0.9618147243;
  transform: rotate(236.3129586026deg);
  -webkit-animation: drop-70 4.782463561s 0.2847116183s infinite;
  animation: drop-70 4.782463561s 0.2847116183s infinite;
}

@-webkit-keyframes drop-70 {
  100% {
    top: 110%;
    left: 92%;
  }
}

@keyframes drop-70 {
  100% {
    top: 110%;
    left: 92%;
  }
}
.confetti-71 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 80%;
  opacity: 1.0001957512;
  transform: rotate(234.611711592deg);
  -webkit-animation: drop-71 4.3262606214s 0.393597313s infinite;
  animation: drop-71 4.3262606214s 0.393597313s infinite;
}

@-webkit-keyframes drop-71 {
  100% {
    top: 110%;
    left: 90%;
  }
}

@keyframes drop-71 {
  100% {
    top: 110%;
    left: 90%;
  }
}
.confetti-72 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 83%;
  opacity: 0.6880527562;
  transform: rotate(159.3339048108deg);
  -webkit-animation: drop-72 4.3087954588s 0.2188625365s infinite;
  animation: drop-72 4.3087954588s 0.2188625365s infinite;
}

@-webkit-keyframes drop-72 {
  100% {
    top: 110%;
    left: 95%;
  }
}

@keyframes drop-72 {
  100% {
    top: 110%;
    left: 95%;
  }
}
.confetti-73 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 58%;
  opacity: 1.3042286327;
  transform: rotate(156.6539770934deg);
  -webkit-animation: drop-73 4.4326371906s 0.3806875203s infinite;
  animation: drop-73 4.4326371906s 0.3806875203s infinite;
}

@-webkit-keyframes drop-73 {
  100% {
    top: 110%;
    left: 72%;
  }
}

@keyframes drop-73 {
  100% {
    top: 110%;
    left: 72%;
  }
}
.confetti-74 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 67%;
  opacity: 1.3649970775;
  transform: rotate(86.2527101664deg);
  -webkit-animation: drop-74 4.0992214648s 0.9887093125s infinite;
  animation: drop-74 4.0992214648s 0.9887093125s infinite;
}

@-webkit-keyframes drop-74 {
  100% {
    top: 110%;
    left: 82%;
  }
}

@keyframes drop-74 {
  100% {
    top: 110%;
    left: 82%;
  }
}
.confetti-75 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 89%;
  opacity: 1.4527615636;
  transform: rotate(295.8305041267deg);
  -webkit-animation: drop-75 4.1451541207s 0.1475651053s infinite;
  animation: drop-75 4.1451541207s 0.1475651053s infinite;
}

@-webkit-keyframes drop-75 {
  100% {
    top: 110%;
    left: 100%;
  }
}

@keyframes drop-75 {
  100% {
    top: 110%;
    left: 100%;
  }
}
.confetti-76 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 62%;
  opacity: 0.6836032696;
  transform: rotate(347.3227698847deg);
  -webkit-animation: drop-76 4.7669782777s 0.2518394528s infinite;
  animation: drop-76 4.7669782777s 0.2518394528s infinite;
}

@-webkit-keyframes drop-76 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-76 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-77 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 84%;
  opacity: 1.0169184963;
  transform: rotate(154.4135020224deg);
  -webkit-animation: drop-77 4.2441127113s 0.4373723549s infinite;
  animation: drop-77 4.2441127113s 0.4373723549s infinite;
}

@-webkit-keyframes drop-77 {
  100% {
    top: 110%;
    left: 98%;
  }
}

@keyframes drop-77 {
  100% {
    top: 110%;
    left: 98%;
  }
}
.confetti-78 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 50%;
  opacity: 1.4351978777;
  transform: rotate(92.8939214398deg);
  -webkit-animation: drop-78 4.4101814946s 0.8109257094s infinite;
  animation: drop-78 4.4101814946s 0.8109257094s infinite;
}

@-webkit-keyframes drop-78 {
  100% {
    top: 110%;
    left: 62%;
  }
}

@keyframes drop-78 {
  100% {
    top: 110%;
    left: 62%;
  }
}
.confetti-79 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 22%;
  opacity: 1.4744347403;
  transform: rotate(244.2347634019deg);
  -webkit-animation: drop-79 4.3801454555s 0.5493432583s infinite;
  animation: drop-79 4.3801454555s 0.5493432583s infinite;
}

@-webkit-keyframes drop-79 {
  100% {
    top: 110%;
    left: 32%;
  }
}

@keyframes drop-79 {
  100% {
    top: 110%;
    left: 32%;
  }
}
.confetti-80 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 11%;
  opacity: 1.1564067597;
  transform: rotate(15.0475940279deg);
  -webkit-animation: drop-80 4.4314851694s 0.6039281871s infinite;
  animation: drop-80 4.4314851694s 0.6039281871s infinite;
}

@-webkit-keyframes drop-80 {
  100% {
    top: 110%;
    left: 18%;
  }
}

@keyframes drop-80 {
  100% {
    top: 110%;
    left: 18%;
  }
}
.confetti-81 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 49%;
  opacity: 0.7197263537;
  transform: rotate(334.2490215353deg);
  -webkit-animation: drop-81 4.4090521025s 0.8821139805s infinite;
  animation: drop-81 4.4090521025s 0.8821139805s infinite;
}

@-webkit-keyframes drop-81 {
  100% {
    top: 110%;
    left: 62%;
  }
}

@keyframes drop-81 {
  100% {
    top: 110%;
    left: 62%;
  }
}
.confetti-82 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 55%;
  opacity: 0.5905958396;
  transform: rotate(22.9011899094deg);
  -webkit-animation: drop-82 4.9980510441s 0.8120553643s infinite;
  animation: drop-82 4.9980510441s 0.8120553643s infinite;
}

@-webkit-keyframes drop-82 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-82 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.confetti-83 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 77%;
  opacity: 1.1099828218;
  transform: rotate(138.2641929564deg);
  -webkit-animation: drop-83 4.922502407s 0.7287655123s infinite;
  animation: drop-83 4.922502407s 0.7287655123s infinite;
}

@-webkit-keyframes drop-83 {
  100% {
    top: 110%;
    left: 89%;
  }
}

@keyframes drop-83 {
  100% {
    top: 110%;
    left: 89%;
  }
}
.confetti-84 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 18%;
  opacity: 0.6320699326;
  transform: rotate(195.8567891293deg);
  -webkit-animation: drop-84 4.0562781863s 0.8721689407s infinite;
  animation: drop-84 4.0562781863s 0.8721689407s infinite;
}

@-webkit-keyframes drop-84 {
  100% {
    top: 110%;
    left: 32%;
  }
}

@keyframes drop-84 {
  100% {
    top: 110%;
    left: 32%;
  }
}
.confetti-85 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 62%;
  opacity: 1.3080953942;
  transform: rotate(280.9560430037deg);
  -webkit-animation: drop-85 4.21987667s 0.0847041505s infinite;
  animation: drop-85 4.21987667s 0.0847041505s infinite;
}

@-webkit-keyframes drop-85 {
  100% {
    top: 110%;
    left: 63%;
  }
}

@keyframes drop-85 {
  100% {
    top: 110%;
    left: 63%;
  }
}
.confetti-86 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 36%;
  opacity: 1.3716794677;
  transform: rotate(223.1450408254deg);
  -webkit-animation: drop-86 4.6879217558s 0.0881746077s infinite;
  animation: drop-86 4.6879217558s 0.0881746077s infinite;
}

@-webkit-keyframes drop-86 {
  100% {
    top: 110%;
    left: 50%;
  }
}

@keyframes drop-86 {
  100% {
    top: 110%;
    left: 50%;
  }
}
.confetti-87 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 35%;
  opacity: 0.5065804008;
  transform: rotate(288.3706838479deg);
  -webkit-animation: drop-87 4.6004904905s 0.7348124872s infinite;
  animation: drop-87 4.6004904905s 0.7348124872s infinite;
}

@-webkit-keyframes drop-87 {
  100% {
    top: 110%;
    left: 36%;
  }
}

@keyframes drop-87 {
  100% {
    top: 110%;
    left: 36%;
  }
}
.confetti-88 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 11%;
  opacity: 1.3190919864;
  transform: rotate(0.8520015313deg);
  -webkit-animation: drop-88 4.2077126373s 0.1748402237s infinite;
  animation: drop-88 4.2077126373s 0.1748402237s infinite;
}

@-webkit-keyframes drop-88 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-88 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-89 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 15%;
  opacity: 1.3633470599;
  transform: rotate(86.3078961945deg);
  -webkit-animation: drop-89 4.1250055732s 0.5990939755s infinite;
  animation: drop-89 4.1250055732s 0.5990939755s infinite;
}

@-webkit-keyframes drop-89 {
  100% {
    top: 110%;
    left: 29%;
  }
}

@keyframes drop-89 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.confetti-90 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 58%;
  opacity: 0.9480550502;
  transform: rotate(334.2835853565deg);
  -webkit-animation: drop-90 4.4442281643s 0.4031939484s infinite;
  animation: drop-90 4.4442281643s 0.4031939484s infinite;
}

@-webkit-keyframes drop-90 {
  100% {
    top: 110%;
    left: 66%;
  }
}

@keyframes drop-90 {
  100% {
    top: 110%;
    left: 66%;
  }
}
.confetti-91 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 30%;
  opacity: 0.5022996753;
  transform: rotate(178.4340915824deg);
  -webkit-animation: drop-91 4.6519168432s 0.4083654511s infinite;
  animation: drop-91 4.6519168432s 0.4083654511s infinite;
}

@-webkit-keyframes drop-91 {
  100% {
    top: 110%;
    left: 39%;
  }
}

@keyframes drop-91 {
  100% {
    top: 110%;
    left: 39%;
  }
}
.confetti-92 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 33%;
  opacity: 1.3873269489;
  transform: rotate(89.0624352202deg);
  -webkit-animation: drop-92 4.2962932101s 0.1099793206s infinite;
  animation: drop-92 4.2962932101s 0.1099793206s infinite;
}

@-webkit-keyframes drop-92 {
  100% {
    top: 110%;
    left: 42%;
  }
}

@keyframes drop-92 {
  100% {
    top: 110%;
    left: 42%;
  }
}
.confetti-93 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 14%;
  opacity: 0.9143896865;
  transform: rotate(266.8629447479deg);
  -webkit-animation: drop-93 4.7013466825s 0.1883806281s infinite;
  animation: drop-93 4.7013466825s 0.1883806281s infinite;
}

@-webkit-keyframes drop-93 {
  100% {
    top: 110%;
    left: 23%;
  }
}

@keyframes drop-93 {
  100% {
    top: 110%;
    left: 23%;
  }
}
.confetti-94 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 30%;
  opacity: 0.5334035366;
  transform: rotate(252.0753717923deg);
  -webkit-animation: drop-94 4.83768957s 0.523251519s infinite;
  animation: drop-94 4.83768957s 0.523251519s infinite;
}

@-webkit-keyframes drop-94 {
  100% {
    top: 110%;
    left: 43%;
  }
}

@keyframes drop-94 {
  100% {
    top: 110%;
    left: 43%;
  }
}
.confetti-95 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 60%;
  opacity: 0.6242079621;
  transform: rotate(42.7221665502deg);
  -webkit-animation: drop-95 4.1677186691s 0.4861947638s infinite;
  animation: drop-95 4.1677186691s 0.4861947638s infinite;
}

@-webkit-keyframes drop-95 {
  100% {
    top: 110%;
    left: 73%;
  }
}

@keyframes drop-95 {
  100% {
    top: 110%;
    left: 73%;
  }
}
.confetti-96 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 96%;
  opacity: 0.6018281109;
  transform: rotate(46.49353213deg);
  -webkit-animation: drop-96 4.2953450012s 0.7056308827s infinite;
  animation: drop-96 4.2953450012s 0.7056308827s infinite;
}

@-webkit-keyframes drop-96 {
  100% {
    top: 110%;
    left: 101%;
  }
}

@keyframes drop-96 {
  100% {
    top: 110%;
    left: 101%;
  }
}
.confetti-97 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 42%;
  opacity: 0.5937394143;
  transform: rotate(238.9594748893deg);
  -webkit-animation: drop-97 4.4270651132s 0.6256332866s infinite;
  animation: drop-97 4.4270651132s 0.6256332866s infinite;
}

@-webkit-keyframes drop-97 {
  100% {
    top: 110%;
    left: 57%;
  }
}

@keyframes drop-97 {
  100% {
    top: 110%;
    left: 57%;
  }
}
.confetti-98 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 50%;
  opacity: 1.4795385231;
  transform: rotate(12.789856087deg);
  -webkit-animation: drop-98 4.1202753694s 0.0363166475s infinite;
  animation: drop-98 4.1202753694s 0.0363166475s infinite;
}

@-webkit-keyframes drop-98 {
  100% {
    top: 110%;
    left: 60%;
  }
}

@keyframes drop-98 {
  100% {
    top: 110%;
    left: 60%;
  }
}
.confetti-99 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 1%;
  opacity: 0.9292339311;
  transform: rotate(39.1926496477deg);
  -webkit-animation: drop-99 4.9739558172s 0.186394191s infinite;
  animation: drop-99 4.9739558172s 0.186394191s infinite;
}

@-webkit-keyframes drop-99 {
  100% {
    top: 110%;
    left: 2%;
  }
}

@keyframes drop-99 {
  100% {
    top: 110%;
    left: 2%;
  }
}
.confetti-100 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 77%;
  opacity: 1.3439152392;
  transform: rotate(131.8049041584deg);
  -webkit-animation: drop-100 4.0496960324s 0.5778807117s infinite;
  animation: drop-100 4.0496960324s 0.5778807117s infinite;
}

@-webkit-keyframes drop-100 {
  100% {
    top: 110%;
    left: 79%;
  }
}

@keyframes drop-100 {
  100% {
    top: 110%;
    left: 79%;
  }
}
.confetti-101 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 16%;
  opacity: 1.3564362756;
  transform: rotate(49.7594037736deg);
  -webkit-animation: drop-101 4.2025561088s 0.2724601426s infinite;
  animation: drop-101 4.2025561088s 0.2724601426s infinite;
}

@-webkit-keyframes drop-101 {
  100% {
    top: 110%;
    left: 29%;
  }
}

@keyframes drop-101 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.confetti-102 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 16%;
  opacity: 1.0417758321;
  transform: rotate(106.321270614deg);
  -webkit-animation: drop-102 4.4889756125s 0.1859837888s infinite;
  animation: drop-102 4.4889756125s 0.1859837888s infinite;
}

@-webkit-keyframes drop-102 {
  100% {
    top: 110%;
    left: 27%;
  }
}

@keyframes drop-102 {
  100% {
    top: 110%;
    left: 27%;
  }
}
.confetti-103 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 88%;
  opacity: 1.1245624399;
  transform: rotate(154.8797264178deg);
  -webkit-animation: drop-103 4.3117427519s 0.1785004197s infinite;
  animation: drop-103 4.3117427519s 0.1785004197s infinite;
}

@-webkit-keyframes drop-103 {
  100% {
    top: 110%;
    left: 93%;
  }
}

@keyframes drop-103 {
  100% {
    top: 110%;
    left: 93%;
  }
}
.confetti-104 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 36%;
  opacity: 1.371543822;
  transform: rotate(281.4345863448deg);
  -webkit-animation: drop-104 4.4284738873s 0.8131577325s infinite;
  animation: drop-104 4.4284738873s 0.8131577325s infinite;
}

@-webkit-keyframes drop-104 {
  100% {
    top: 110%;
    left: 39%;
  }
}

@keyframes drop-104 {
  100% {
    top: 110%;
    left: 39%;
  }
}
.confetti-105 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 24%;
  opacity: 1.1786362163;
  transform: rotate(32.5766826696deg);
  -webkit-animation: drop-105 4.7922666596s 0.9701131758s infinite;
  animation: drop-105 4.7922666596s 0.9701131758s infinite;
}

@-webkit-keyframes drop-105 {
  100% {
    top: 110%;
    left: 30%;
  }
}

@keyframes drop-105 {
  100% {
    top: 110%;
    left: 30%;
  }
}
.confetti-106 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 31%;
  opacity: 1.4234705739;
  transform: rotate(187.3636387652deg);
  -webkit-animation: drop-106 4.7085121703s 0.1576589458s infinite;
  animation: drop-106 4.7085121703s 0.1576589458s infinite;
}

@-webkit-keyframes drop-106 {
  100% {
    top: 110%;
    left: 33%;
  }
}

@keyframes drop-106 {
  100% {
    top: 110%;
    left: 33%;
  }
}
.confetti-107 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 69%;
  opacity: 0.7318501094;
  transform: rotate(160.1465764405deg);
  -webkit-animation: drop-107 4.8136020428s 0.442077853s infinite;
  animation: drop-107 4.8136020428s 0.442077853s infinite;
}

@-webkit-keyframes drop-107 {
  100% {
    top: 110%;
    left: 77%;
  }
}

@keyframes drop-107 {
  100% {
    top: 110%;
    left: 77%;
  }
}
.confetti-108 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 52%;
  opacity: 1.38712439;
  transform: rotate(68.3600658773deg);
  -webkit-animation: drop-108 4.9227470456s 0.7981238444s infinite;
  animation: drop-108 4.9227470456s 0.7981238444s infinite;
}

@-webkit-keyframes drop-108 {
  100% {
    top: 110%;
    left: 63%;
  }
}

@keyframes drop-108 {
  100% {
    top: 110%;
    left: 63%;
  }
}
.confetti-109 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 69%;
  opacity: 1.1810178073;
  transform: rotate(188.7138761084deg);
  -webkit-animation: drop-109 4.2112860821s 0.2565532855s infinite;
  animation: drop-109 4.2112860821s 0.2565532855s infinite;
}

@-webkit-keyframes drop-109 {
  100% {
    top: 110%;
    left: 72%;
  }
}

@keyframes drop-109 {
  100% {
    top: 110%;
    left: 72%;
  }
}
.confetti-110 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 15%;
  opacity: 1.2515222802;
  transform: rotate(111.6980406173deg);
  -webkit-animation: drop-110 4.5309132379s 0.62853973s infinite;
  animation: drop-110 4.5309132379s 0.62853973s infinite;
}

@-webkit-keyframes drop-110 {
  100% {
    top: 110%;
    left: 25%;
  }
}

@keyframes drop-110 {
  100% {
    top: 110%;
    left: 25%;
  }
}
.confetti-111 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 65%;
  opacity: 0.9785324758;
  transform: rotate(280.5405335245deg);
  -webkit-animation: drop-111 4.5248817483s 0.1761307028s infinite;
  animation: drop-111 4.5248817483s 0.1761307028s infinite;
}

@-webkit-keyframes drop-111 {
  100% {
    top: 110%;
    left: 69%;
  }
}

@keyframes drop-111 {
  100% {
    top: 110%;
    left: 69%;
  }
}
.confetti-112 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 51%;
  opacity: 1.2197451639;
  transform: rotate(280.9287608788deg);
  -webkit-animation: drop-112 4.4271960757s 0.8503509712s infinite;
  animation: drop-112 4.4271960757s 0.8503509712s infinite;
}

@-webkit-keyframes drop-112 {
  100% {
    top: 110%;
    left: 59%;
  }
}

@keyframes drop-112 {
  100% {
    top: 110%;
    left: 59%;
  }
}
.confetti-113 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 88%;
  opacity: 0.8640876586;
  transform: rotate(109.2603543137deg);
  -webkit-animation: drop-113 4.9743342297s 0.6507145252s infinite;
  animation: drop-113 4.9743342297s 0.6507145252s infinite;
}

@-webkit-keyframes drop-113 {
  100% {
    top: 110%;
    left: 89%;
  }
}

@keyframes drop-113 {
  100% {
    top: 110%;
    left: 89%;
  }
}
.confetti-114 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 4%;
  opacity: 0.5489860818;
  transform: rotate(33.5417729042deg);
  -webkit-animation: drop-114 4.5161983517s 0.1926086707s infinite;
  animation: drop-114 4.5161983517s 0.1926086707s infinite;
}

@-webkit-keyframes drop-114 {
  100% {
    top: 110%;
    left: 17%;
  }
}

@keyframes drop-114 {
  100% {
    top: 110%;
    left: 17%;
  }
}
.confetti-115 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 9%;
  opacity: 0.9109131501;
  transform: rotate(8.5560409903deg);
  -webkit-animation: drop-115 4.7169045027s 0.1472988819s infinite;
  animation: drop-115 4.7169045027s 0.1472988819s infinite;
}

@-webkit-keyframes drop-115 {
  100% {
    top: 110%;
    left: 11%;
  }
}

@keyframes drop-115 {
  100% {
    top: 110%;
    left: 11%;
  }
}
.confetti-116 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 70%;
  opacity: 0.7240434372;
  transform: rotate(35.0036254807deg);
  -webkit-animation: drop-116 4.0477154467s 0.3753613777s infinite;
  animation: drop-116 4.0477154467s 0.3753613777s infinite;
}

@-webkit-keyframes drop-116 {
  100% {
    top: 110%;
    left: 74%;
  }
}

@keyframes drop-116 {
  100% {
    top: 110%;
    left: 74%;
  }
}
.confetti-117 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 20%;
  opacity: 1.3788130503;
  transform: rotate(220.3895141537deg);
  -webkit-animation: drop-117 4.322215817s 0.5297087773s infinite;
  animation: drop-117 4.322215817s 0.5297087773s infinite;
}

@-webkit-keyframes drop-117 {
  100% {
    top: 110%;
    left: 27%;
  }
}

@keyframes drop-117 {
  100% {
    top: 110%;
    left: 27%;
  }
}
.confetti-118 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 69%;
  opacity: 1.4407070573;
  transform: rotate(295.2845018042deg);
  -webkit-animation: drop-118 4.1997526968s 0.3598937685s infinite;
  animation: drop-118 4.1997526968s 0.3598937685s infinite;
}

@-webkit-keyframes drop-118 {
  100% {
    top: 110%;
    left: 84%;
  }
}

@keyframes drop-118 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-119 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 99%;
  opacity: 1.3510105468;
  transform: rotate(226.9820891597deg);
  -webkit-animation: drop-119 4.3870108256s 0.1464037779s infinite;
  animation: drop-119 4.3870108256s 0.1464037779s infinite;
}

@-webkit-keyframes drop-119 {
  100% {
    top: 110%;
    left: 106%;
  }
}

@keyframes drop-119 {
  100% {
    top: 110%;
    left: 106%;
  }
}
.confetti-120 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 63%;
  opacity: 1.219669227;
  transform: rotate(272.7627066661deg);
  -webkit-animation: drop-120 4.9892848155s 0.8062844215s infinite;
  animation: drop-120 4.9892848155s 0.8062844215s infinite;
}

@-webkit-keyframes drop-120 {
  100% {
    top: 110%;
    left: 67%;
  }
}

@keyframes drop-120 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.confetti-121 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 27%;
  opacity: 0.8072969699;
  transform: rotate(234.5789751012deg);
  -webkit-animation: drop-121 4.9438164355s 0.3983031747s infinite;
  animation: drop-121 4.9438164355s 0.3983031747s infinite;
}

@-webkit-keyframes drop-121 {
  100% {
    top: 110%;
    left: 42%;
  }
}

@keyframes drop-121 {
  100% {
    top: 110%;
    left: 42%;
  }
}
.confetti-122 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 49%;
  opacity: 0.5385291923;
  transform: rotate(307.4735346839deg);
  -webkit-animation: drop-122 4.0404655752s 0.6369265858s infinite;
  animation: drop-122 4.0404655752s 0.6369265858s infinite;
}

@-webkit-keyframes drop-122 {
  100% {
    top: 110%;
    left: 59%;
  }
}

@keyframes drop-122 {
  100% {
    top: 110%;
    left: 59%;
  }
}
.confetti-123 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 84%;
  opacity: 0.5525969336;
  transform: rotate(7.6238997285deg);
  -webkit-animation: drop-123 4.6518027404s 0.8837145872s infinite;
  animation: drop-123 4.6518027404s 0.8837145872s infinite;
}

@-webkit-keyframes drop-123 {
  100% {
    top: 110%;
    left: 91%;
  }
}

@keyframes drop-123 {
  100% {
    top: 110%;
    left: 91%;
  }
}
.confetti-124 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 82%;
  opacity: 0.7854645129;
  transform: rotate(328.8437461129deg);
  -webkit-animation: drop-124 4.6396491542s 0.783849181s infinite;
  animation: drop-124 4.6396491542s 0.783849181s infinite;
}

@-webkit-keyframes drop-124 {
  100% {
    top: 110%;
    left: 83%;
  }
}

@keyframes drop-124 {
  100% {
    top: 110%;
    left: 83%;
  }
}
.confetti-125 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 33%;
  opacity: 0.9301804003;
  transform: rotate(167.7297318622deg);
  -webkit-animation: drop-125 4.2363335071s 0.9397649445s infinite;
  animation: drop-125 4.2363335071s 0.9397649445s infinite;
}

@-webkit-keyframes drop-125 {
  100% {
    top: 110%;
    left: 40%;
  }
}

@keyframes drop-125 {
  100% {
    top: 110%;
    left: 40%;
  }
}
.confetti-126 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 92%;
  opacity: 1.3164345897;
  transform: rotate(337.6322498873deg);
  -webkit-animation: drop-126 4.4760096486s 0.168005423s infinite;
  animation: drop-126 4.4760096486s 0.168005423s infinite;
}

@-webkit-keyframes drop-126 {
  100% {
    top: 110%;
    left: 94%;
  }
}

@keyframes drop-126 {
  100% {
    top: 110%;
    left: 94%;
  }
}
.confetti-127 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 13%;
  opacity: 0.7237763467;
  transform: rotate(211.3105158089deg);
  -webkit-animation: drop-127 4.7328047848s 0.2176314715s infinite;
  animation: drop-127 4.7328047848s 0.2176314715s infinite;
}

@-webkit-keyframes drop-127 {
  100% {
    top: 110%;
    left: 28%;
  }
}

@keyframes drop-127 {
  100% {
    top: 110%;
    left: 28%;
  }
}
.confetti-128 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 12%;
  opacity: 1.0859155284;
  transform: rotate(257.3922871767deg);
  -webkit-animation: drop-128 4.9278611911s 0.0007869428s infinite;
  animation: drop-128 4.9278611911s 0.0007869428s infinite;
}

@-webkit-keyframes drop-128 {
  100% {
    top: 110%;
    left: 13%;
  }
}

@keyframes drop-128 {
  100% {
    top: 110%;
    left: 13%;
  }
}
.confetti-129 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 31%;
  opacity: 0.6280876152;
  transform: rotate(52.8272080356deg);
  -webkit-animation: drop-129 4.8316993498s 0.6631300863s infinite;
  animation: drop-129 4.8316993498s 0.6631300863s infinite;
}

@-webkit-keyframes drop-129 {
  100% {
    top: 110%;
    left: 45%;
  }
}

@keyframes drop-129 {
  100% {
    top: 110%;
    left: 45%;
  }
}
.confetti-130 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 21%;
  opacity: 0.5207171683;
  transform: rotate(22.494070606deg);
  -webkit-animation: drop-130 4.3152060928s 0.1203401394s infinite;
  animation: drop-130 4.3152060928s 0.1203401394s infinite;
}

@-webkit-keyframes drop-130 {
  100% {
    top: 110%;
    left: 35%;
  }
}

@keyframes drop-130 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-131 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 59%;
  opacity: 1.2507905809;
  transform: rotate(257.7442374663deg);
  -webkit-animation: drop-131 4.4218319787s 0.5660007355s infinite;
  animation: drop-131 4.4218319787s 0.5660007355s infinite;
}

@-webkit-keyframes drop-131 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-131 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.confetti-132 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 70%;
  opacity: 1.4849233091;
  transform: rotate(157.9025164129deg);
  -webkit-animation: drop-132 4.1242809984s 0.3962036422s infinite;
  animation: drop-132 4.1242809984s 0.3962036422s infinite;
}

@-webkit-keyframes drop-132 {
  100% {
    top: 110%;
    left: 74%;
  }
}

@keyframes drop-132 {
  100% {
    top: 110%;
    left: 74%;
  }
}
.confetti-133 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 41%;
  opacity: 0.8995623659;
  transform: rotate(69.9974547504deg);
  -webkit-animation: drop-133 4.7080032689s 0.4740897406s infinite;
  animation: drop-133 4.7080032689s 0.4740897406s infinite;
}

@-webkit-keyframes drop-133 {
  100% {
    top: 110%;
    left: 53%;
  }
}

@keyframes drop-133 {
  100% {
    top: 110%;
    left: 53%;
  }
}
.confetti-134 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 23%;
  opacity: 0.594641226;
  transform: rotate(3.7536746134deg);
  -webkit-animation: drop-134 4.3076265251s 0.3276293425s infinite;
  animation: drop-134 4.3076265251s 0.3276293425s infinite;
}

@-webkit-keyframes drop-134 {
  100% {
    top: 110%;
    left: 27%;
  }
}

@keyframes drop-134 {
  100% {
    top: 110%;
    left: 27%;
  }
}
.confetti-135 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 53%;
  opacity: 1.3392528642;
  transform: rotate(141.6479912352deg);
  -webkit-animation: drop-135 4.0997217502s 0.7743831552s infinite;
  animation: drop-135 4.0997217502s 0.7743831552s infinite;
}

@-webkit-keyframes drop-135 {
  100% {
    top: 110%;
    left: 56%;
  }
}

@keyframes drop-135 {
  100% {
    top: 110%;
    left: 56%;
  }
}
.confetti-136 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 88%;
  opacity: 0.6750031224;
  transform: rotate(354.7906635722deg);
  -webkit-animation: drop-136 4.8813927402s 0.4885393833s infinite;
  animation: drop-136 4.8813927402s 0.4885393833s infinite;
}

@-webkit-keyframes drop-136 {
  100% {
    top: 110%;
    left: 94%;
  }
}

@keyframes drop-136 {
  100% {
    top: 110%;
    left: 94%;
  }
}
.confetti-137 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 6%;
  opacity: 1.0607872463;
  transform: rotate(161.8858866841deg);
  -webkit-animation: drop-137 4.2924034252s 0.3934549529s infinite;
  animation: drop-137 4.2924034252s 0.3934549529s infinite;
}

@-webkit-keyframes drop-137 {
  100% {
    top: 110%;
    left: 14%;
  }
}

@keyframes drop-137 {
  100% {
    top: 110%;
    left: 14%;
  }
}
.confetti-138 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 82%;
  opacity: 0.5844878141;
  transform: rotate(125.8263272672deg);
  -webkit-animation: drop-138 4.2452544505s 0.8969586502s infinite;
  animation: drop-138 4.2452544505s 0.8969586502s infinite;
}

@-webkit-keyframes drop-138 {
  100% {
    top: 110%;
    left: 92%;
  }
}

@keyframes drop-138 {
  100% {
    top: 110%;
    left: 92%;
  }
}
.confetti-139 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 49%;
  opacity: 1.0322186615;
  transform: rotate(66.674252535deg);
  -webkit-animation: drop-139 4.2266265886s 0.0669326802s infinite;
  animation: drop-139 4.2266265886s 0.0669326802s infinite;
}

@-webkit-keyframes drop-139 {
  100% {
    top: 110%;
    left: 63%;
  }
}

@keyframes drop-139 {
  100% {
    top: 110%;
    left: 63%;
  }
}
.confetti-140 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 6%;
  opacity: 0.647822266;
  transform: rotate(105.8417441749deg);
  -webkit-animation: drop-140 4.9008867183s 0.0886002459s infinite;
  animation: drop-140 4.9008867183s 0.0886002459s infinite;
}

@-webkit-keyframes drop-140 {
  100% {
    top: 110%;
    left: 8%;
  }
}

@keyframes drop-140 {
  100% {
    top: 110%;
    left: 8%;
  }
}
.confetti-141 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 16%;
  opacity: 1.2436781152;
  transform: rotate(298.8068954521deg);
  -webkit-animation: drop-141 4.9526825668s 0.8989162154s infinite;
  animation: drop-141 4.9526825668s 0.8989162154s infinite;
}

@-webkit-keyframes drop-141 {
  100% {
    top: 110%;
    left: 27%;
  }
}

@keyframes drop-141 {
  100% {
    top: 110%;
    left: 27%;
  }
}
.confetti-142 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 53%;
  opacity: 1.3935257595;
  transform: rotate(90.2481429053deg);
  -webkit-animation: drop-142 4.6029935176s 0.2406579144s infinite;
  animation: drop-142 4.6029935176s 0.2406579144s infinite;
}

@-webkit-keyframes drop-142 {
  100% {
    top: 110%;
    left: 58%;
  }
}

@keyframes drop-142 {
  100% {
    top: 110%;
    left: 58%;
  }
}
.confetti-143 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 46%;
  opacity: 1.459116687;
  transform: rotate(89.4984064882deg);
  -webkit-animation: drop-143 4.7589465419s 0.2673862845s infinite;
  animation: drop-143 4.7589465419s 0.2673862845s infinite;
}

@-webkit-keyframes drop-143 {
  100% {
    top: 110%;
    left: 48%;
  }
}

@keyframes drop-143 {
  100% {
    top: 110%;
    left: 48%;
  }
}
.confetti-144 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 54%;
  opacity: 1.1491651255;
  transform: rotate(291.1690418111deg);
  -webkit-animation: drop-144 4.7248983269s 0.4863973014s infinite;
  animation: drop-144 4.7248983269s 0.4863973014s infinite;
}

@-webkit-keyframes drop-144 {
  100% {
    top: 110%;
    left: 56%;
  }
}

@keyframes drop-144 {
  100% {
    top: 110%;
    left: 56%;
  }
}
.confetti-145 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 7%;
  opacity: 1.1148898799;
  transform: rotate(291.1285092961deg);
  -webkit-animation: drop-145 4.2457122116s 0.2774204867s infinite;
  animation: drop-145 4.2457122116s 0.2774204867s infinite;
}

@-webkit-keyframes drop-145 {
  100% {
    top: 110%;
    left: 13%;
  }
}

@keyframes drop-145 {
  100% {
    top: 110%;
    left: 13%;
  }
}
.confetti-146 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 36%;
  opacity: 0.8868535118;
  transform: rotate(203.7455187506deg);
  -webkit-animation: drop-146 4.5554956784s 0.181078099s infinite;
  animation: drop-146 4.5554956784s 0.181078099s infinite;
}

@-webkit-keyframes drop-146 {
  100% {
    top: 110%;
    left: 37%;
  }
}

@keyframes drop-146 {
  100% {
    top: 110%;
    left: 37%;
  }
}
.confetti-147 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 72%;
  opacity: 0.8429887463;
  transform: rotate(274.4731540785deg);
  -webkit-animation: drop-147 4.9366546251s 0.8351328341s infinite;
  animation: drop-147 4.9366546251s 0.8351328341s infinite;
}

@-webkit-keyframes drop-147 {
  100% {
    top: 110%;
    left: 85%;
  }
}

@keyframes drop-147 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.confetti-148 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 17%;
  opacity: 1.0146524284;
  transform: rotate(38.7006443631deg);
  -webkit-animation: drop-148 4.4364604913s 0.4662871376s infinite;
  animation: drop-148 4.4364604913s 0.4662871376s infinite;
}

@-webkit-keyframes drop-148 {
  100% {
    top: 110%;
    left: 32%;
  }
}

@keyframes drop-148 {
  100% {
    top: 110%;
    left: 32%;
  }
}
.confetti-149 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 66%;
  opacity: 1.3863349566;
  transform: rotate(71.2432734484deg);
  -webkit-animation: drop-149 4.8911483532s 0.1505359337s infinite;
  animation: drop-149 4.8911483532s 0.1505359337s infinite;
}

@-webkit-keyframes drop-149 {
  100% {
    top: 110%;
    left: 77%;
  }
}

@keyframes drop-149 {
  100% {
    top: 110%;
    left: 77%;
  }
}
.confetti-150 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 21%;
  opacity: 1.4924798012;
  transform: rotate(111.5487224943deg);
  -webkit-animation: drop-150 4.7078963539s 0.9219478473s infinite;
  animation: drop-150 4.7078963539s 0.9219478473s infinite;
}

@-webkit-keyframes drop-150 {
  100% {
    top: 110%;
    left: 28%;
  }
}

@keyframes drop-150 {
  100% {
    top: 110%;
    left: 28%;
  }
}
</style>